import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKey } from "../i18n";

interface FormProperty {
  [key: string]: {
    validation?: Validation;
    value?: any;
  };
}

interface Validation {
  array?: boolean;
  boolean?: boolean;
  date?: boolean;
  email?: boolean;
  min?: { value: number };
  minLength?: { length: number };
  max?: { value: number };
  maxLength?: { length: number };
  numeric?: boolean;
  required?: boolean;
}

export interface Form<T> {
  [key: string]: {
    error?: string | null;
    valid: boolean;
    validation?: Validation;
    value: any;
    blurHandler: () => void;
    changeHandler: (event: any) => void;
  };
  /* @ts-ignore */
  __initialValue: T;
  /* @ts-ignore */
  __primaryKey: string;
  /* @ts-ignore */
  __value: T;
  /* @ts-ignore */
  __clear: () => void;
  /* @ts-ignore */
  __setValue: (value: T) => void;
  /* @ts-ignore */
  __validate: () => boolean;
}

export const CreateForm = <T>(properties: FormProperty): Form<T> => {
  const { t } = useTranslation();

  const clear = () => {
    const temForm = { ...form };

    for (const property in temForm) {
      if (property.includes("__")) continue;

      temForm[property].value =
        temForm.__initialValue[property] === null
          ? ""
          : temForm.__initialValue[property];
      temForm[property].valid = true;
      temForm[property].error = null;
    }

    temForm.__value = temForm.__initialValue;
    setForm(temForm);
  };

  const getValue = (property: any) => {
    if (property.validation?.array) {
      return property.value ? property.value : [];
    } else if (property.validation?.boolean) {
      return property.value;
    } else if (property.validation?.date) {
      return property.value ? property.value : null;
    } else if (property.validation?.numeric) {
      return !isNaN(+property.value) ? +property.value : null;
    } else {
      return property.value ? property.value : null;
    }
  };

  const setValue = (value: Record<string, any>) => {
    const temForm = { ...form };

    for (const propertyName in value) {
      const property = value[propertyName];

      if (temForm[propertyName] !== undefined && property !== undefined) {
        temForm[propertyName].value = property;
        temForm[propertyName].valid = true;
        temForm[propertyName].error = null;
        temForm.__value[propertyName] = value[propertyName];
      }
    }

    setForm(temForm);
  };

  const validate = (): boolean => {
    let valid = true;

    const v = (previousValue: any) => {
      const tempValue = { ...previousValue };

      for (const propertyName in tempValue) {
        if (propertyName.includes("__")) continue;

        const property = tempValue[propertyName];
        const error = validateProperty(property.value, property.validation);
        property.valid = !error;
        property.error = error;

        if (valid) valid = property.valid;
      }

      return tempValue;
    };

    setForm((previousValue: any) => v(previousValue));
    return valid;
  };

  /* @ts-ignore */
  const schema: Form = {
    __initialValue: {},
    __value: {},
    __clear: clear,
    __setValue: setValue,
    __validate: validate,
  };

  for (const propertyName in properties) {
    if (propertyName.includes("__")) continue;

    schema[propertyName] = {
      value:
        properties[propertyName].value === undefined
          ? ""
          : properties[propertyName].value,
      valid: true,
      validation: properties[propertyName].validation,
      blurHandler: () => blurHandler(propertyName),
      changeHandler: (event: any) => propertyChangeHandler(event, propertyName),
    };

    schema.__initialValue[propertyName] = getValue(schema[propertyName]);
    schema.__value[propertyName] = schema.__initialValue[propertyName];
  }

  schema.__primaryKey = Object.keys(properties)[0];
  const [form, setForm] = useState(schema);

  const blurHandler = (propertyName: string) => {
    const validateHandler = (previousValue: any) => {
      const error = validateProperty(
        previousValue[propertyName].value,
        previousValue[propertyName].validation
      );

      const formProperty = updateObject(previousValue[propertyName], {
        valid: !error,
        error,
      });

      return updateObject(previousValue, {
        [propertyName]: formProperty,
      });
    };

    setForm((previousValue: any) => validateHandler(previousValue));
  };

  const propertyChangeHandler = (event: any, propertyName: string) => {
    const changeHandler = (previousValue: Form<T>) => {
      const value = event.target ? event.target.value : event;

      const formProperty = updateObject(previousValue[propertyName], {
        value,
      });

      return updateObject(previousValue, {
        [propertyName]: formProperty,
        __value: previousValue.__value
          ? {
              ...previousValue.__value,
              [propertyName]: getValue(formProperty),
            }
          : { [propertyName]: getValue(formProperty) },
      });
    };

    setForm((previousValue: Form<T>) => changeHandler(previousValue));
  };

  const updateObject = (oldObject: any, updatedProperties: any) => {
    return {
      ...oldObject,
      ...updatedProperties,
    };
  };

  const validateProperty = (value: any, validation: Validation | undefined) => {
    if (!validation) return;

    if (validation.required) {
      if (typeof value === "string" && value.trim() === "") {
        return t(TranslationKey.Required);
      } else if (Array.isArray(value) && value.length === 0) {
        return t(TranslationKey.Required);
      } else if (value === "") {
        return t(TranslationKey.Required);
      }
    }

    if (validation.min && value < validation.min.value) {
      return `Min value (${validation.min.value}).`;
    }

    if (validation.minLength && value.length < validation.minLength.length) {
      return `Minimum (${validation.minLength.length}) characters.`;
    }

    if (validation.max && value > validation.max.value) {
      return `Max value (${validation.max.value}).`;
    }

    if (validation.maxLength && value.length > validation.maxLength.length) {
      return `Maximum (${validation.maxLength.length}) characters.`;
    }

    if (validation.email) {
      const pattern =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

      if (!pattern.test(value)) {
        return t(TranslationKey.InvalidEmail);
      }
    }

    if (value && validation.numeric) {
      if (isNaN(value)) {
        return t(TranslationKey.InvalidNumber);
      }
    }
  };

  return form;
};

export const validation = {
  min: (value: number) => {
    return { value };
  },
  minLength: (length: number) => {
    return { length };
  },
  max: (value: number) => {
    return { value };
  },
  maxLength: (length: number) => {
    return { length };
  },
};
